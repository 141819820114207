/* eslint-disable */

<template>
  <div>
    <vue-sortable :options="options" @drag:start="startedDrag" @drag:stop="stoppedDrag" @sortable:sort="sort">
      <div :class="{ 'cursor-drag': draggingCard === true }" class="vx-row">
        <div class="vx-col lg:w-3/12 md:w-3/12 sm:w-5/5">
          <vx-card class="mb-4">
            <h3 class="font-bold mb-4">Controls</h3>
            <vue-draggable-container id="wordBank" class="wordBank">
              <h5 class="dragBlock font-semibold cursor-pointer ports-block">Cable Locks</h5>
              <h5 class="dragBlock font-semibold cursor-pointer ports-block">Cable Locks</h5>
              <h5 class="dragBlock font-semibold cursor-pointer ports-block">Cable Locks</h5>
              <h5 class="dragBlock font-semibold cursor-pointer ports-block">Cable Locks</h5>
              <h5 class="dragBlock font-semibold cursor-pointer ports-block">Cable Locks</h5>
              <h5 class="dragBlock font-semibold cursor-pointer ports-block">Cable Locks</h5>
              <h5 class="dragBlock font-semibold cursor-pointer ports-block">Proximity Badge</h5>
              <h5 class="dragBlock font-semibold cursor-pointer ports-block">Proximity Badge</h5>
              <h5 class="dragBlock font-semibold cursor-pointer ports-block">Biometric Reader</h5>
              <h5 class="dragBlock font-semibold cursor-pointer ports-block">Biometric Reader</h5>
              <h5 class="dragBlock font-semibold cursor-pointer ports-block">Biometric Reader</h5>
              <h5 class="dragBlock font-semibold cursor-pointer ports-block">Biometric Reader</h5>
              <h5 class="dragBlock font-semibold cursor-pointer ports-block">Man Trap</h5>
              <h5 class="dragBlock font-semibold cursor-pointer ports-block">CCTV</h5>
              <h5 class="dragBlock font-semibold cursor-pointer ports-block">Safe (Key Box)</h5>
              <h5 class="dragBlock font-semibold cursor-pointer ports-block">Locking Cabinets</h5>
            </vue-draggable-container>
          </vx-card>
        </div>
        <div class="vx-col lg:w-9/12 md:w-9/12 sm:w-5/5">
          <vx-card class="mb-base">
            <div class="fourth-sim pt-0">
              <div class="grid grid-cols-3 gap-4">
                <div class="col-span-2">
                  <div class="border-white border-solid p-2 rounded h-full">
                    <h1 class="mb-2">Unsupervised Lab</h1>
                    <div class="">
                      <div class="grid grid-cols-4 gap-4 flex items-center">
                        <div class="p-1">
                          <div class="port-inside">
                            <div class="flex justify-center">
                              <img
                                draggable="false"
                                alt=""
                                class="port-icon"
                                height="45"
                                sizes="100vw"
                                src="https://rapidsimulations.com/simulations/1/images/printer.png"
                                srcset="
                                  https://rapidsimulations.com/simulations/1/images/printer.png 500w,
                                  https://rapidsimulations.com/simulations/1/images/printer.png 512w
                                "
                                width="45"
                              />
                            </div>
                            <div class="flex justify-center">
                              <p class="port-question-title">Printer</p>
                            </div>
                          </div>
                        </div>
                        <div class="p-1">
                          <div class="port-inside">
                            <div class="flex justify-center">
                              <img
                                draggable="false"
                                alt=""
                                class="port-icon"
                                height="45"
                                sizes="100vw"
                                src="https://rapidsimulations.com/simulations/1/images/laptop.png"
                                srcset="
                                  https://rapidsimulations.com/simulations/1/images/laptop.png 500w,
                                  https://rapidsimulations.com/simulations/1/images/laptop.png 512w
                                "
                                width="45"
                              />
                            </div>
                          </div>
                          <vue-draggable-container
                            id="input0"
                            class="answerStandard dropzone dropzoneStandard answer-bg"
                          ></vue-draggable-container>
                        </div>
                        <div class="p-1">
                          <div class="port-inside">
                            <div class="flex justify-center">
                              <img
                                draggable="false"
                                alt=""
                                class="port-icon"
                                height="45"
                                sizes="100vw"
                                src="https://rapidsimulations.com/simulations/1/images/laptop.png"
                                srcset="
                                  https://rapidsimulations.com/simulations/1/images/laptop.png 500w,
                                  https://rapidsimulations.com/simulations/1/images/laptop.png 512w
                                "
                                width="45"
                              />
                            </div>
                          </div>
                          <vue-draggable-container
                            id="input1"
                            class="answerStandard dropzone dropzoneStandard answer-bg"
                          ></vue-draggable-container>
                        </div>
                        <div class="p-1">
                          <div class="port-inside">
                            <div class="flex justify-center">
                              <img
                                draggable="false"
                                alt=""
                                class="port-icon"
                                height="45"
                                sizes="100vw"
                                src="https://image.flaticon.com/icons/svg/2857/2857702.svg"
                                srcset="
                                  https://rapidsimulations.com/simulations/1/images/laptop.png 500w,
                                  https://rapidsimulations.com/simulations/1/images/laptop.png 512w
                                "
                                width="45"
                              />
                            </div>
                          </div>
                          <vue-draggable-container
                            id="input2"
                            class="answerStandard dropzone dropzoneStandard answer-bg"
                          ></vue-draggable-container>
                        </div>
                      </div>
                      <div class="grid grid-cols-4 gap-4 flex items-center">
                        <div class="p-1">
                          <div class="port-inside">
                            <div class="flex justify-center">
                              <img
                                draggable="false"
                                alt=""
                                class="port-icon"
                                height="45"
                                sizes="100vw"
                                src="https://rapidsimulations.com/simulations/1/images/printer.png"
                                srcset="
                                  https://rapidsimulations.com/simulations/1/images/printer.png 500w,
                                  https://rapidsimulations.com/simulations/1/images/printer.png 512w
                                "
                                width="45"
                              />
                            </div>
                            <div class="flex justify-center">
                              <p class="port-question-title">Printer</p>
                            </div>
                          </div>
                        </div>
                        <div class="p-1">
                          <div class="port-inside">
                            <div class="flex justify-center">
                              <img
                                draggable="false"
                                alt=""
                                class="port-icon"
                                height="45"
                                sizes="100vw"
                                src="https://rapidsimulations.com/simulations/1/images/laptop.png"
                                srcset="
                                  https://rapidsimulations.com/simulations/1/images/laptop.png 500w,
                                  https://rapidsimulations.com/simulations/1/images/laptop.png 512w
                                "
                                width="45"
                              />
                            </div>
                          </div>
                          <vue-draggable-container
                            id="input3"
                            class="answerStandard dropzone dropzoneStandard answer-bg"
                          ></vue-draggable-container>
                        </div>
                        <div class="p-1">
                          <div class="port-inside">
                            <div class="flex justify-center">
                              <img
                                draggable="false"
                                alt=""
                                class="port-icon"
                                height="45"
                                sizes="100vw"
                                src="https://rapidsimulations.com/simulations/1/images/laptop.png"
                                srcset="
                                  https://rapidsimulations.com/simulations/1/images/laptop.png 500w,
                                  https://rapidsimulations.com/simulations/1/images/laptop.png 512w
                                "
                                width="45"
                              />
                            </div>
                          </div>
                          <vue-draggable-container
                            id="input4"
                            class="answerStandard dropzone dropzoneStandard answer-bg"
                          ></vue-draggable-container>
                        </div>
                        <div class="p-1">
                          <div class="port-inside">
                            <div class="flex justify-center">
                              <img
                                draggable="false"
                                alt=""
                                class="port-icon"
                                height="45"
                                sizes="100vw"
                                src="https://image.flaticon.com/icons/svg/2857/2857702.svg"
                                srcset="
                                  https://rapidsimulations.com/simulations/1/images/laptop.png 500w,
                                  https://rapidsimulations.com/simulations/1/images/laptop.png 512w
                                "
                                width="45"
                              />
                            </div>
                          </div>
                          <vue-draggable-container
                            id="input5"
                            class="answerStandard dropzone dropzoneStandard answer-bg"
                          ></vue-draggable-container>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="">
                  <div class="border-white border-solid p-2 rounded">
                    <div class="grid grid-cols-2 gap-4">
                      <div class="col-span-2">
                        <div class="flex justify-center">
                          <img
                            draggable="false"
                            alt=""
                            class="port-icon"
                            height="45"
                            sizes="100vw"
                            src="https://rapidsimulations.com/simulations/1/images/wifi-2-p-500.png"
                            srcset="
                              https://rapidsimulations.com/simulations/1/images/wifi-2-p-500.png 500w,
                              https://rapidsimulations.com/simulations/1/images/wifi-2-p-500.png 512w
                            "
                            width="45"
                          />
                          <p class="port-question-title">Employee Laptop<br /></p>
                        </div>
                        <vue-draggable-container
                          id="input6"
                          class="answerStandard dropzone dropzoneStandard answer-bg"
                        ></vue-draggable-container>
                      </div>
                      <div class="col-span-2">
                        <div class="flex justify-center">
                          <img
                            draggable="false"
                            alt=""
                            class="port-icon"
                            height="45"
                            sizes="100vw"
                            src="https://rapidsimulations.com/simulations/1/images/wifi-2-p-500.png"
                            srcset="
                              https://rapidsimulations.com/simulations/1/images/wifi-2-p-500.png 500w,
                              https://rapidsimulations.com/simulations/1/images/wifi-2-p-500.png 512w
                            "
                            width="45"
                          />
                          <p class="port-question-title">Employee Laptop<br /></p>
                        </div>
                        <vue-draggable-container
                          id="input7"
                          class="answerStandard dropzone dropzoneStandard answer-bg"
                        ></vue-draggable-container>
                      </div>
                      <div class="col-span-2">
                        <div class="flex justify-center">
                          <img
                            draggable="false"
                            alt=""
                            class="port-icon"
                            height="45"
                            sizes="100vw"
                            src="https://rapidsimulations.com/simulations/1/images/wifi-2-p-500.png"
                            srcset="
                              https://rapidsimulations.com/simulations/1/images/wifi-2-p-500.png 500w,
                              https://rapidsimulations.com/simulations/1/images/wifi-2-p-500.png 512w
                            "
                            width="45"
                          />
                          <p class="port-question-title">Employee Laptop<br /></p>
                        </div>
                        <vue-draggable-container
                          id="input8"
                          class="answerStandard dropzone dropzoneStandard answer-bg"
                        ></vue-draggable-container>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="">
                  <div class="border-white border-solid p-2 rounded h-full">
                    <h1 class="mb-2">Office</h1>
                    <div class="grid grid-cols-1 gap-4">
                      <div class="">
                        <div class="port-inside">
                          <div class="flex justify-center">
                            <img
                              draggable="false"
                              alt=""
                              class="port-icon"
                              height="45"
                              sizes="100vw"
                              src="https://rapidsimulations.com/simulations/1/images/web-design-p-500.png"
                              srcset="
                                https://rapidsimulations.com/simulations/1/images/web-design-p-500.png 500w,
                                https://rapidsimulations.com/simulations/1/images/web-design-p-500.png 512w
                              "
                              width="45"
                            />
                            <p class="port-question-title">Workstation<br /></p>
                          </div>
                        </div>
                      </div>
                      <div class="">
                        <div class="port-inside">
                          <div class="flex justify-center">
                            <img
                              draggable="false"
                              alt=""
                              class="port-icon"
                              height="45"
                              sizes="100vw"
                              src="https://rapidsimulations.com/simulations/1/images/laptop.png"
                              srcset="
                                https://rapidsimulations.com/simulations/1/images/laptop.png 500w,
                                https://rapidsimulations.com/simulations/1/images/laptop.png 512w
                              "
                              width="45"
                            />
                            <p class="port-question-title">Laptop<br /></p>
                          </div>
                        </div>
                      </div>
                      <div class="">
                        <div class="port-inside">
                          <div class="flex justify-center">
                            <img
                              draggable="false"
                              alt=""
                              class="port-icon"
                              height="45"
                              sizes="100vw"
                              src="https://rapidsimulations.com/simulations/1/images/printer.png"
                              srcset="
                                https://rapidsimulations.com/simulations/1/images/printer.png 500w,
                                https://rapidsimulations.com/simulations/1/images/printer.png 512w
                              "
                              width="45"
                            />
                            <p class="port-question-title">Printer<br /></p>
                          </div>
                        </div>
                      </div>

                      <div class="">
                        <vue-draggable-container
                          id="input9"
                          class="answerStandard dropzone dropzoneStandard answer-bg"
                        ></vue-draggable-container>
                      </div>
                      <div class="">
                        <vue-draggable-container
                          id="input10"
                          class="answerStandard dropzone dropzoneStandard answer-bg"
                        ></vue-draggable-container>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-span-2">
                  <div class="border-white border-solid p-2 rounded">
                    <h1 class="mb-2">Data Center</h1>
                    <div class="grid grid-cols-2 gap-4">
                      <div class="vx-col grid grid-cols-2">
                        <div>
                          <div class="flex align-center justify-center">
                            <img
                              draggable="false"
                              alt=""
                              class="port-icon"
                              height="45"
                              sizes="100vw"
                              src="https://connect-cdn.intellectualpoint.com/assets/images/simulations/security-plus/249112018676613120/database-storage.png"
                              width="45"
                            />
                          </div>
                          <p class="port-question-title text-center">Server<br /></p>
                        </div>
                        <div>
                          <div class="flex align-center justify-center">
                            <img
                              draggable="false"
                              alt=""
                              class="port-icon"
                              height="45"
                              sizes="100vw"
                              src="https://connect-cdn.intellectualpoint.com/assets/images/simulations/security-plus/249112018676613120/database-storage.png"
                              width="45"
                            />
                          </div>
                          <p class="port-question-title text-center">Server<br /></p>
                        </div>
                        <div>
                          <div class="flex align-center justify-center">
                            <img
                              draggable="false"
                              alt=""
                              class="port-icon"
                              height="45"
                              sizes="100vw"
                              src="https://connect-cdn.intellectualpoint.com/assets/images/simulations/security-plus/249112018676613120/database-storage.png"
                              width="45"
                            />
                          </div>
                          <p class="port-question-title text-center">Server<br /></p>
                        </div>
                        <div>
                          <div class="flex align-center justify-center">
                            <img
                              draggable="false"
                              alt=""
                              class="port-icon"
                              height="45"
                              sizes="100vw"
                              src="https://connect-cdn.intellectualpoint.com/assets/images/simulations/security-plus/249112018676613120/database-storage.png"
                              width="45"
                            />
                          </div>
                          <p class="port-question-title text-center">Server<br /></p>
                        </div>
                        <div>
                          <div class="flex align-center justify-center">
                            <img
                              draggable="false"
                              alt=""
                              class="port-icon"
                              height="45"
                              sizes="100vw"
                              src="https://connect-cdn.intellectualpoint.com/assets/images/simulations/security-plus/249112018676613120/database-storage.png"
                              width="45"
                            />
                          </div>
                          <p class="port-question-title text-center">Server<br /></p>
                        </div>
                        <div>
                          <div class="flex align-center justify-center">
                            <img
                              draggable="false"
                              alt=""
                              class="port-icon"
                              height="45"
                              sizes="100vw"
                              src="https://connect-cdn.intellectualpoint.com/assets/images/simulations/security-plus/249112018676613120/database-storage.png"
                              width="45"
                            />
                          </div>
                          <p class="port-question-title text-center">Server<br /></p>
                        </div>
                      </div>
                      <div class="vx-col">
                        <div class="w:1/2">
                          <div class="p-1">
                            <vue-draggable-container
                              id="input11"
                              class="answerStandard dropzone dropzoneStandard answer-bg"
                            ></vue-draggable-container>
                          </div>
                          <div class="p-1">
                            <vue-draggable-container
                              id="input12"
                              class="answerStandard dropzone dropzoneStandard answer-bg"
                            ></vue-draggable-container>
                          </div>
                          <div class="p-1">
                            <vue-draggable-container
                              id="input13"
                              class="answerStandard dropzone dropzoneStandard answer-bg"
                            ></vue-draggable-container>
                          </div>
                          <div class="p-1">
                            <vue-draggable-container
                              id="input14"
                              class="answerStandard dropzone dropzoneStandard answer-bg"
                            ></vue-draggable-container>
                          </div>
                          <div class="p-1">
                            <vue-draggable-container
                              id="input15"
                              class="answerStandard dropzone dropzoneStandard answer-bg"
                            ></vue-draggable-container>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </vx-card>
        </div>
      </div>
    </vue-sortable>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import Prism from 'vue-prism-component';

export default {
  data() {
    return {
      dataLocal: JSON.parse(JSON.stringify(this.data)),
      draggingCard: false,

      office: ['Proximity Badge', 'Safe (Key Box)'],

      data_center: ['Man Trap', 'Proximity Badge', 'CCTV', 'Biometric Reader', 'Locking Cabinets'],

      options: {
        draggable: '.dragBlock',
        dropzone: '.dropzoneStandard',
      },
    };
  },
  methods: {
    startedDrag(x) {
      x.data.source.className += ' currently-dragging-standard';
      this.draggingCard = true;
    },
    stoppedDrag() {
      this.draggingCard = false;
    },
    sort(x) {
      const overContainer = x.data.dragEvent.data.overContainer;
      const sourceContainer = x.data.dragEvent.data.sourceContainer;
      if (overContainer && overContainer.id !== 'wordBank' && overContainer.children) {
        if (overContainer.children.length > 0 && overContainer.id !== sourceContainer.id) {
          //this.drag_error()
          x.cancel();
        }
      }
    },
    markSimulation() {
      let totalScore = 0;

      for (let i = 0; i < 6; i++) {
        if (document.getElementById(`input${i}`).textContent === 'Cable Locks') {
          totalScore++;
          document.getElementById(`input${i}`).style.borderColor = '#28C76F';
          document.getElementById(`input${i}`).style.backgroundColor = '#28C76F';
        }
      }

      for (let i = 0; i < 3; i++) {
        if (document.getElementById(`input${i + 6}`).textContent === 'Biometric Reader') {
          totalScore++;
          document.getElementById(`input${i + 6}`).style.borderColor = '#28C76F';
          document.getElementById(`input${i + 6}`).style.backgroundColor = '#28C76F';
        }
      }

      const office_used = [];
      this.office.forEach(function (x, index) {
        for (let i = 0; i < 2; i++) {
          if (document.getElementById(`input${i + 9}`).textContent === x) {
            if (office_used.includes(index)) {
              return false;
            }

            office_used.push(index);

            totalScore++;
            document.getElementById(`input${i + 9}`).style.borderColor = '#28C76F';
            document.getElementById(`input${i + 9}`).style.backgroundColor = '#28C76F';
          }
        }
      });

      const data_center_used = [];
      this.data_center.forEach(function (x, index) {
        for (let i = 0; i < 5; i++) {
          if (document.getElementById(`input${i + 11}`).textContent === x) {
            if (data_center_used.includes(index)) {
              return false;
            }

            data_center_used.push(index);

            totalScore++;
            document.getElementById(`input${i + 11}`).style.borderColor = '#28C76F';
            document.getElementById(`input${i + 11}`).style.backgroundColor = '#28C76F';
          }
        }
      });

      if (totalScore === this.dataLocal.simulation.totalScore) {
        return this.$emit('simulation_full_correct', totalScore);
      }

      this.$emit('simulation_getting_there', totalScore);
    },
  },
  components: {
    draggable,
    Prism,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>
